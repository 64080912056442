





import React, { useEffect, useState, useRef } from "react";
import Footer from "../../../component/Footer";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import { Link, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Modal } from "react-bootstrap";
import axios from "axios";
import config from "../../../component/config";

const CreateStudent = () => {
  const navigate = useNavigate();
  const toast = useRef(null);

  const [formData, setFormData] = useState({
    teacher_id: localStorage.getItem("userId"),
    name: "",
    mobile: "",
    email: "",
    commission: "10",
    lot_size_limit: "1",
    broker_api_key: "",
    broker_client_id: "",
    broker_password: "",
    broker_qr_totp_token: ""
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [withBrokerDetails, setWithBrokerDetails] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
  
    try {
      const authToken = localStorage.getItem('authToken');
      const userId = localStorage.getItem('userId');
  
      if (!authToken || !userId) {
        navigate("/commonlogin");
        return;
      }
  
      let apiUrl = `${config.apiDomain}/api/teacher/manage_students/create`;
  
      // Append with_broker_details query parameter based on checkbox state
      apiUrl += `?with_broker_details=${withBrokerDetails ? 'True' : 'False'}`;
  
      // Create a copy of formData to avoid mutating state directly
      const formDataToSubmit = { ...formData };
  
      // Include broker details if the checkbox is checked
      if (!withBrokerDetails) {
        // Remove broker details if not needed
        delete formDataToSubmit.broker_api_key;
        delete formDataToSubmit.broker_client_id;
        delete formDataToSubmit.broker_password;
        delete formDataToSubmit.broker_qr_totp_token;
      }
  
      const response = await axios.post(apiUrl, formDataToSubmit, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (response.data && response.data.st === 1) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: formatMessage(response.data.msg) || "Success!",
          className: "text-start",
          life: 3000,
        });
  
        setTimeout(() => {
          navigate("/teacher/manage_student");
        }, 1000);
      } else if (response.data && response.data.st === 2) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: formatMessage(response.data.msg) || "Warning: Please check the input.",
          life: 3000,
        });
      } else if (
        response.data &&
        (response.data.st === 3 || response.data.st === 4)
      ) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: formatMessage(response.data.msg) || "An error occurred.",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Unexpected response status",
          life: 3000,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/commonlogin");
      } else {
        const errorMsg = error.response?.data?.msg
          ? formatMessage(error.response.data.msg)
          : String(error.message || "Failed to fetch data");
  
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
          className: "text-start",
        });
      }
    } finally {
      setLoading(false);
    }
  };
  

  

  const formatMessage = (msg) => {
    if (typeof msg === "object") {
      return Object.values(msg).flat().join(", ");
    }
    return String(msg);
  };

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 15)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  const getColorModalClass = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9 || hours === 15) {
      return hours === 9 ? "modal-green" : "modal-red";
    }
    return "";
  };

  const getModalTitle = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "Market is Open!";
    } else if (hours === 15) {
      return "Market is Closed!";
    }
    return "";
  };

  const getModalBody = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "Market is currently open. Take necessary actions.";
    } else if (hours === 15) {
      return "Market is currently closed. Come back tomorrow.";
    }
    return "";
  };

  return (
    <>
      <Header />
      <SubHeader />
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container-xxl flex-grow-1 container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-secondary">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-secondary">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                to="/teacher/manage_student"
                className="text-secondary"
              >
                Manage Student
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Create Student
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col-5 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-circle-fill me-1 ri-md"></i>{" "}
                Back
              </button>
            </div>
            <div className="col-6 text-start mb-5">
              <h5 className="mb-0">Create Student</h5>
            </div>
          </div>
          <div className="row">
            <h5 className="text-start">
              {" "}
              <i className="ri-user-line ri-ms me-2"></i>
              Personal Information
            </h5>
            <div className="col-12">
              <form onSubmit={handleSubmit}>
                <div className="row mt-1">
                  <div className="col-3">
                    <div className="form-floating form-floating-outline">
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Name"
                        required
                      />
                      <label htmlFor="name">
                        {" "}
                        <span className="text-danger">*</span> Name{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-floating form-floating-outline">
                      <input
                        className="form-control"
                        type="text"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="E-mail"
                        required
                      />
                      <label htmlFor="email">
                        {" "}
                        <span className="text-danger">*</span> E-mail{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-floating form-floating-outline">
                      <input
                        className="form-control"
                        type="text"
                        id="mobile"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        placeholder="Mobile"
                        required
                      />
                      <label htmlFor="mobile">
                        {" "}
                        <span className="text-danger">*</span> Mobile{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-1">
                    <div className="form-floating form-floating-outline">
                      <input
                        className="form-control"
                        type="text"
                        id="lot_size_limit"
                        name="lot_size_limit"
                        value={formData.lot_size_limit}
                        onChange={handleChange}
                        placeholder="Lot size limit"
                        required
                      />
                      <label htmlFor="lot_size_limit">
                        {" "}
                        <span className="text-danger">*</span> Lot size limit{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-1">
                    <div className="form-floating form-floating-outline">
                      <input
                        className="form-control"
                        type="text"
                        id="commission"
                        name="commission"
                        value={formData.commission}
                        onChange={handleChange}
                        placeholder="Commission"
                        required
                      />
                      <label htmlFor="commission">
                        {" "}
                        <span className="text-danger">*</span> Commission{" "}
                      </label>
                    </div>
                  </div>
                 
               
                </div>
                
                 

                <div className="col-12 mt-3">
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        id="withBrokerDetails"
        name="withBrokerDetails"
        checked={withBrokerDetails}
        onChange={() => setWithBrokerDetails(!withBrokerDetails)}
      />
      <label className="form-check-label" htmlFor="withBrokerDetails">
        Include Broker Details
      </label>
    </div>
  </div>

  {withBrokerDetails && (
    <>
      <h5 className="text-start mt-4">
        <i className="ri-user-line ri-ms me-2"></i> Broker Information
      </h5>
      <div className="row mt-1">
        <div className="col-3">
          <div className="form-floating form-floating-outline">
            <input
              className="form-control"
              type="text"
              id="broker_api_key"
              name="broker_api_key"
              value={formData.broker_api_key}
              onChange={handleChange}
              placeholder="Broker API Key"
            />
            <label htmlFor="broker_api_key">Broker API Key</label>
          </div>
        </div>
        <div className="col-3">
          <div className="form-floating form-floating-outline">
            <input
              className="form-control"
              type="text"
              id="broker_client_id"
              name="broker_client_id"
              value={formData.broker_client_id}
              onChange={handleChange}
              placeholder="Broker Client ID"
            />
            <label htmlFor="broker_client_id">Broker Client ID</label>
          </div>
        </div>
        <div className="col-3">
          <div className="form-floating form-floating-outline">
            <input
              className="form-control"
              type="text"
              id="broker_password"
              name="broker_password"
              value={formData.broker_password}
              onChange={handleChange}
              placeholder="Broker Password"
            />
            <label htmlFor="broker_password">Broker Password</label>
          </div>
        </div>
        <div className="col-3">
          <div className="form-floating form-floating-outline">
            <input
              className="form-control"
              type="text"
              id="broker_qr_totp_token"
              name="broker_qr_totp_token"
              value={formData.broker_qr_totp_token}
              onChange={handleChange}
              placeholder="Broker QR TOTP Token"
            />
            <label htmlFor="broker_qr_totp_token">Broker QR TOTP Token</label>
          </div>
        </div>
      </div>
    </>
  )}


                <div className="row mt-4">
                  <div className="col-12 text-end">
                  <button
                      type="submit"
                      className="btn rounded-pill btn-success btn-sm"
                      disabled={loading}
                      
                    >
                      {loading ? (
                        <div className="spinner-border me-2" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      ) : (
                        <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                      )}{" "}
                      {loading ? "Saving Data" : "Save Data"}
                      
                    </button>

                   
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Toast ref={toast} />
    </>
  );
};

export default CreateStudent;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; // Apply custom modal background colors
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};








