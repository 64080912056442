import React, { useEffect, useState ,useRef} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import config from "../../../component/config";
import Footer from "../../../component/Footer"
import SubHeaderS from "./SubHeaderS";
import StudentHeader from "./StudentHeader";
import { Toast } from "primereact/toast";
const StudentMyReportView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [backClicked, setBackClicked] = useState(false);
  const toast = useRef(null);
  const [errors, setErrors] = useState({});
  const [month, setMonth] = useState("");
  
  const authToken = localStorage.getItem("authToken");
  const [isRefreshing,setIsRefreshing] = useState(true);
  const [summary, setSummary] = useState({
    total_trades_count: 0,
    total_profitable_trades: 0,
    total_losing_trades: 0,
    total_commission: 0.0,
  });

  useEffect(() => {
    const { state } = location;


    if (!state || !state.userId || !state.month) {
      navigate(-1); 
      return;
    }

 
    setMonth(state.month);
    fetchData(state.userId, state.month);
  }, [location, navigate]);

  const fetchData = async (userId, month) => {
    setLoading(true);
    setError(null);

    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");

      if (!authToken || !userId) {
        navigate("/commonlogin");
        return;
      }
      const response = await axios.post(
        `${config.apiDomain}/api/common/trade_details`,
        {
          user_id: userId,
          sell_date: month,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`, 
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        setData(response.data.trades);
        setSummary(response.data.completed_trades_aggregate);
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      console.clear(); 
      if (error.response && error.response.status === 401) {
       
        navigate("/commonlogin"); 
      } else {
       
      }
    } finally {
      setLoading(false);
    }
  };

  // const handleRefresh = async () => {
  //   setLoading(true);
  //   setError(null);
  //   setIsRefreshing(true);
  
  //   try {
  //     const { userId, month } = location.state;
  //     const response = await axios.post(
  //       `${config.apiDomain}/api/common/trade_details`,
  //       {
  //         user_id: userId,
  //         sell_date: month,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${authToken}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  
  //     const { data } = response;
  //     const errorMsg = formatMessage(data?.msg) || "An unexpected error occurred";
  
  //     if (data) {
  //       setData(data.trades);
  //       setSummary(data.completed_trades_aggregate);
  
  //       switch (data.st) {
  //         case 1:
  //           // window.showToast("success", data.msg || "Data fetched successfully");
  //           break;
  //         case 2:
  //           window.showToast("warning", errorMsg || "Warning: Check the trade details.");
  //           break;
  //         case 3:
  //         case 4:
  //           window.showToast("danger", errorMsg || "Error: Unable to fetch trade details.");
  //           break;
  //         default:
  //           setErrors({ global: "Unexpected response status" });
  //           window.showToast("error", "Something went wrong. Contact Support.");
  //       }
  //     } else {
  //       setErrors({ global: "No data found" });
  //       window.showToast("error", "No data found");
  //     }
  //   } catch (error) {
  //     const errorMsg = error.response?.data?.msg
  //       ? formatMessage(error.response.data.msg)
  //       : String(error.message || "Something went wrong. Contact Support.");
  
  //     window.showToast("error", errorMsg);
  //   } finally {
  //     setLoading(false);
  //     setIsRefreshing(false);
  //   }
  // };
  

  const formatMessage = (msg) => {
    if (typeof msg === "object") {
      return Object.values(msg).flat().join(", ");
    }
    return String(msg);
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
 

  const [showPopup, setShowPopup] = useState(false); 

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

    
      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); 


    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

 
  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success"; 
    } else if (hours === 15) {
      return "danger"; 
    }
    return "secondary"; 
  };

  const CommissionBodyTemplate = (rowData) => {
    return ` ${rowData.commission} %`;
  };

 

  const PLTemplate = (rowData) => {
    return ` ${rowData.pandl} Rs.`;
  };

  const PLTotalBodyTemplate = (rowData) => {
    return ` ${rowData.pandl_total} Rs.`;
  };

  const PLPercentTemplate = (rowData) => {
    return ` ${rowData.pandl_percent} %`;
  };

  const formatDateTime = (datetime) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    return new Date(datetime).toLocaleTimeString("en-US", options);
  };

  const formatDateDay = (datetime) => {
    return new Date(datetime).getDate();
  };

  const formatDateMonth = (datetime) => {
    return new Date(datetime).toLocaleString("default", { month: "short" });
  };

  const formatDateYear = (datetime) => {
    return new Date(datetime).getFullYear();
  };
  const buyColumnTemplate = (rowData) => {
    return (
      <div>
        <div className="mb-1">
          <strong>{rowData.entry_type}</strong>
         {" = "} <strong>{rowData.entry_price} ₹</strong> | qty:
          <strong>
          {" "} 
          {rowData.entry_lotsize}
           ({rowData.entry_stock_quantity})
          </strong>
        </div>
        
        <div className="mb-1">{rowData.entry_datetime}
        {" | "}
        <span>{rowData.entry_button_type}</span>
        </div>
        <div className="mb-1">
          <strong>
          {rowData.stock_symbol}
          </strong>
          </div>
        
      </div>
    );
  };
  
  const sellColumnTemplate = (rowData) => {
    return (
      <div>
        <div className="mb-1">
          <strong>{rowData.exit_type}</strong>
          {" = "}
          <strong>{rowData.exit_price} ₹ </strong> | qty:
          <strong>
            {" "}
            {rowData.exit_lotsize}({rowData.exit_stock_quantity})
          </strong>
        </div>
        <div className="mb-1">{rowData.exit_datetime}
{" | "}
        <span>{rowData.exit_button_type}</span>
        </div>
        <div className="mb-1">
          <strong>{rowData.stock_symbol}</strong>
        </div>
      </div>
    );
  };

  const brokerNameMap = {
    angle_one: "AngelOne",
    dhan: "Dhan",
  };
  return (
    <>
      <Toast ref={toast} />
  <StudentHeader />
  <SubHeaderS />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/student/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
           
            <li className="breadcrumb-item">
              <Link to="/app2/student_my_report" className="text-black">
                My Report
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/app2/student_my_report_datewise" className="text-black">
               Datewise
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary" aria-current="page">
              My Report Details
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col text-start mb-5">
              <h5 className="mb-0">  Details</h5>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex align-items-center">
              <IconField iconPosition="left">
                <InputIcon className="ri ri-search-line"></InputIcon>
                <InputText
                  type="search"
                  placeholder="Search"
                  value={globalFilter}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                  className="rounded"
                />
              </IconField>

              {loading ? (
              <div className="d-flex align-items-center">
                <i
                  className={`ri-refresh-line ri-lg ms-3 p-text-secondary ${
                    isRefreshing ? "rotate" : ""
                  }`}
                  role="status"
                ></i>
              </div>
            ) : (
              <div className="">
                <Tooltip target=".custom-target-icon" />
                <i
                  className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                  role="button"
                  data-pr-tooltip="Reload"
                  onClick={fetchData}
                  data-pr-position="top"
                ></i>
              </div>
            )}
            </div>

            <strong className="mt-3 ms-auto  fs-4">{month}</strong>
          </div>
          <DataTable
            style={{ border: "1px solid #ddd" }}
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
          >
            <Column
              align="left"
              style={{ border: "1px solid #ddd" }}
              header="Entry"
              body={buyColumnTemplate}
            ></Column>

            <Column
              align="left"
              style={{ border: "1px solid #ddd" }}
              header="Exit"
              body={sellColumnTemplate}
            ></Column>

            {/* <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="pandl"
              header="P&L"
              body={PLTemplate}
            ></Column> */}
            {/* <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="pandl_total"
              header="Exit Type"
              body={ExitTypeBodyTemplate}
            ></Column> */}
            <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="pandl_total"
              header="P&L Total"
              body={PLTotalBodyTemplate}
            ></Column>
            <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="pandl_percent"
              header="P&L Percent"
              body={PLPercentTemplate}
            ></Column>
            {/* <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="commission"
              header="Commission "
              body={CommissionBodyTemplate}
            ></Column> */}
          </DataTable>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default StudentMyReportView;
